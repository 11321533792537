.App {
  text-align: center;
  z-index: -10;
}

body { background-color: transparent; }

.App-logo {
  z-index: 10;
  height: 60vmin;
  pointer-events: none;
}

.App-logo {
  z-index: 10;
  height: 60vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.link{
  color: black;
  text-decoration: none;

  padding-right: 5px;
}
.link:hover{
  color: grey;

}

.navbar {
/*  border: 1px solid black;*/
  background-color: white;
  z-index: 9999;

}

nav .navbar-collapse {
  /*position: fixed;*/
  top: 56px;
  /*background: #343a40;*/
  /*right: -100%;*/
  /*width: 100vw;*/
  /*height: 100vh;*/
  /*display: block;*/
  /*transition: bottom 0.3s ease;*/
  text-align: right;
  padding-right: 10px;
  justify-content: right;
}

nav .navbar-collapse.show {
  /*transition: bottom 0.3s ease;*/
  right: 0;
  text-align: right;
  padding-right: 10px;
}

:root{
  --stickyHeight: 56px;
  --stickyHeightMin: -56px;
  --buttonsHeightMin: -66px;
  --buttonsHeight: 66px;
}

.img-wrapper{
  overflow:hidden;
/*  transition: transform .5s;*/
}
img.hover-zoom {
  transition: transform .5s;
  
}
img.hover-zoom:hover {
  transform: scale(1.25);
  width:100%;
}

.App-header {
  z-index: -10;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar {display:none;}

.root {
  height:100vh;
  overflow-y:scroll;
/*  paddingTop:156px;*/
/*  border:1px solid black;*/
  width:100%;
  padding-bottom: 20px;
}



.main1 {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  min-height: 100vh;
/*  border: 1px solid blue;*/
/*  overflow-y: scroll;*/
  margin-top: var(--stickyHeight);

}

.horScroll {
  max-width: 100vw;
  overflow-x: scroll;
  padding-top: 30px;
}

.container{
/*  paddingTop:156px;*/
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: left;
/*  border: 1px solid green;*/
  text-align: center;
  position: relative;
}

.image1 {
  width: 100%;
  height: 100%;


  /*margin-top: var(--stickyHeight);*/
}

.notSticky{
  width: 100%;
  animation-name: animaTop1;
  /*animation-duration: 4s;*/
  animation-duration: .25s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
.stickPos{
  position: fixed;
  z-index: 100;
}

.sticky {
  /*top: 0;*/
  width: 100%;
  /*transform: translateY(100%) 2s;*/
  animation-name: animaTop;
  animation-duration: .25s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;

}

.highlight {
  padding:5px;
  background-color:rgba(0, 0, 0, 0.5);
  border-radius:25px;
  -webkit-box-decoration-break: clone;
     -ms-box-decoration-break: clone;
     -o-box-decoration-break: clone;
     box-decoration-break: clone;
}

.menuClassName {
  /*display: flex;*/
/*  border: 1px black solid;*/
  /*align-items: center;*/
  /*display: flex;*/
  max-height: 300px;
  z-index: 1;
  position: absolute;
  overflow-y: scroll;
  background-color: white;
  padding:10px;
/*  width:100%;*/
}
.dropdown {
/*  flex: 1;*/
  /*flex: 8;*/
  /*display: flex;*/
  /*border: 1px black solid;*/
/*  overflow: hidden;*/
/*  border: 1px blue solid;*/
  /*align-items: center;*/
  /*display: flex;*/
/*  overflow: hidden;*/
  z-index: 1;
  position: relative;
  background-color: white;
}
.dropdownC {
/*  border: 1px green solid;*/
  padding: 10px;
  display: flex;
  flex: 1;
/*  z-index: 101;*/
  position: relative;
  background-color: white;
  cursor:pointer;
}
.fuck {
  /*margin: auto;*/
  border-bottom: 1px black solid;
}

button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}


form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}